"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.orderPaymentMachine = void 0;
var xstate_1 = require("xstate");
exports.orderPaymentMachine = 
/** @xstate-layout N4IgpgJg5mDOIC5QAoC2BDAxgCwJYDswBKAOhwHtzYwBJfXAF13QBsBiRUABysd3PycQAD0QA2MQA4SATgBMMqQEYZSpWICsigDQgAnohkAGEkbNm5AdhkAWJZaNyxAX2e60WPIVKZyAeS4AURZcKFwAI1wQhj02AFk-ADVAgBU-QIARGhSABQBBAE04wIA5FKEeWD4BIVEEKTkSdRsjDSMZSxabS0ldAwQbNtkZEck5SUkNB0sAZld3DBwCYhIGcgZWACUwADdcMAB3eKTU9KzcwuKyit4mGqQRRAVLEh7jLQcNMZle-UQbGQaV4AkbjGYjGYTeYgDxLbyrdZbXb7I6bVIAVU2ZT8AGE-H4cjcqndBA86jNHK9JO8Oq1vr9+jMZkoSCCfkpJDM3jMNNDYV4Vlx0HpUGB8Axjsk0niSgAxGibOJ5FI0PwlInVUmgOoSaTyRSSFRqTQ6P71JRA8xmDRfTktFxuGGLAWkIUisUStEpTHYvEEjUk2riKSyBTKVTqLRiPqIDRiF5WowzAFWJRzR385ZENh4nIFADKAAkaDkcjQSgBxAP8LWPAaSF6zMZjMTdIyTOQxhBKJMzUzmKxaOQ2ZnphaeLNsfOpQt5fN+wkPSqaoMDeQkSYyGZibc2SRSHldtRyExWntKcb7yxyPnOycJKW4tXyxXK1Xqpe3Gur+NdySUtoLA0JRug0GZh1vCdvElU5Ak2TY-E2at7m1cRLC7eQXkAxxNBsGwxHsGxXEdfByAgOAhEzeEKCoWh6CYVhkNrOpgKBZMuTaZNMLTI9BlZEYRhAmxxjAndILhFZfACYJQgiKJGH6bgvxQutVBsV4OU6dRCOpGwu3AmQSAUEZWwpPDLA0G8MzveE1g2Fhtj2Q4mNXdRGks5Nh3kMQ5CZLd9KZYZRhUDQR3sJxxJdEg3VFcUXLJWNkyaIxOgUG1qQaLsARMLzQWyiQLMirMyAEAAzXAACcMEDT9iW-BKEDUjSGzsAjOl0rtNEaJkmT3BQuSvIr4TACqKvICr4tQhAKUaN5WlpL4FAZQw5EaK0nBC5MfKG4hJrrEdOv7RNjpS4jnCAA */
(0, xstate_1.createMachine)({
    context: {
        isCoopRequest: false,
        isNewCoopRequest: false,
        isApprovedCoopRequest: false,
        hasCoop: true,
        coopAmount: 0,
        isUsingCoop: false,
        shippingAddress: {
            firstName: '',
            lastName: '',
            address1: '',
            city: '',
            state: '',
            zipCode: '',
            contactName: '',
            email: '',
            phoneNumber: '',
            asmName: '',
            asmEmail: ''
        },
        billingAddress: {
            firstName: '',
            lastName: '',
            address1: '',
            city: '',
            state: '',
            zipCode: '',
        },
        cardNumber: '',
        expirationMonth: '',
        expirationYear: '',
        CCV: '',
    },
    id: '(machine)',
    initial: 'chooseInitial',
    on: {
        COPYSHIPPING: {
            actions: 'copyShipping',
        },
        SETHASCOOP: {
            actions: 'setHasCoop',
        },
        SETISCOOPREQUEST: {
            actions: 'setIsCoopRequest',
        },
        SETCOOPREQUESTAPPROVED: {
            actions: 'setIsApprovedCoopRequest',
        },
        MOVETOCONFIRMATION: {
            target: '.confirmation',
            internal: false,
        },
        MOVETOERROR: {
            target: '.error',
            internal: false,
        },
    },
    states: {
        chooseInitial: {
            always: [
                {
                    target: 'coOpEligibility',
                },
            ],
        },
        coOpEligibility: {
            on: {
                MOVETOTOTALREVIEW: {
                    actions: ['setUsingCoop', 'setCoopAmount', 'setNewCoopRequest'],
                    target: 'totalReview',
                },
            },
        },
        totalReview: {
            on: {
                MOVETOEDITSHIPPING: {
                    cond: 'isCoopRequest',
                    target: 'shipping',
                },
                MOVETOEDITPAYMENT: {
                    target: 'payment',
                },
                RETURNTOCOOP: {
                    target: 'coOpEligibility',
                },
            },
        },
        shipping: {
            on: {
                MOVETOEDITPAYMENT: {
                    actions: 'setShipping',
                    target: 'payment',
                },
                RETURNTOCOOP: {
                    target: 'coOpEligibility',
                }
            },
        },
        payment: {
            on: {
                MOVETOCONFIRMATION: {
                    actions: ['setShipping', 'setBillingAddress', 'setPayment'],
                    target: 'confirmation',
                },
                RETURNTOEDITSHIPPING: {
                    target: 'shipping',
                },
                RETURNTOCOOP: {
                    target: 'coOpEligibility',
                },
            },
        },
        confirmation: {},
        error: {
            on: {
                RETURNTOEDITPAYMENT: {
                    target: 'payment',
                },
            },
        },
    },
}, {
    guards: {
        canSeeCoop: function (context) { return context.hasCoop || context.hasCoop === null || context.isCoopRequest; },
        isCoopRequest: function (context) { return context.isCoopRequest || context.isCoopRequest === null; },
        isApprovedCoopRequest: function (context) { return context.isApprovedCoopRequest || context.isApprovedCoopRequest === null; },
    },
    actions: {
        setHasCoop: (0, xstate_1.assign)({
            hasCoop: function (_context, event) {
                if (event.type !== 'SETHASCOOP')
                    return;
                return event.hasCoop;
            },
        }),
        setIsCoopRequest: (0, xstate_1.assign)({
            isCoopRequest: function (_context, event) {
                if (event.type !== 'SETISCOOPREQUEST')
                    return;
                return event.isCoopRequest;
            },
        }),
        setIsApprovedCoopRequest: (0, xstate_1.assign)({
            isApprovedCoopRequest: function (_context, event) {
                if (event.type !== 'SETCOOPREQUESTAPPROVED')
                    return;
                return event.isApprovedCoopRequest;
            },
        }),
        setUsingCoop: (0, xstate_1.assign)({
            isUsingCoop: function (_context, event) {
                if (event.type !== 'MOVETOTOTALREVIEW')
                    return;
                return event.isUsingCoop;
            },
        }),
        setCoopAmount: (0, xstate_1.assign)({
            coopAmount: function (_context, event) {
                if (event.type !== 'MOVETOTOTALREVIEW')
                    return;
                return event.coopAmount;
            },
        }),
        setBillingAddress: (0, xstate_1.assign)({
            billingAddress: function (_context, event) {
                if (event.type !== 'MOVETOCONFIRMATION')
                    return;
                return event.payment.billingAddress;
            },
        }),
        setPayment: (0, xstate_1.assign)(function (_context, event) {
            if (event.type !== 'MOVETOCONFIRMATION')
                return;
            return {
                cardNumber: event.payment.cardNumber,
                expirationMonth: event.payment.expirationMonth,
                expirationYear: event.payment.expirationYear,
                CCV: event.payment.CCV,
            };
        }),
        copyShipping: (0, xstate_1.assign)({
            billingAddress: function (_context, event) {
                if (event.type !== 'COPYSHIPPING')
                    return;
                return event.shippingAddress;
            },
        }),
        setShipping: (0, xstate_1.assign)({
            shippingAddress: function (_context, event) {
                if (event.type !== 'MOVETOEDITPAYMENT')
                    return;
                return event.shippingAddress;
            },
        }),
        setNewCoopRequest: (0, xstate_1.assign)({
            isNewCoopRequest: function (_context, event) {
                if (event.type !== 'MOVETOTOTALREVIEW')
                    return;
                return event.isNewCoopRequest;
            }
        }),
    },
});
