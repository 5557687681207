import { Spinner } from '@monorepo/components'
import {
  ProductHelper,
  CartHelper,
  EventHelper,
  useChannel,
  formatCurrency,
} from '@monorepo/infra'
import { CartEvent, CartItems, CartProduct } from '@monorepo/interfaces'
import React, { useEffect, useState } from 'react'
import * as S from '../styles'

interface SectionCheckoutCoopProps {
  items: CartItems
  masterDealerId: string
  onUseCoop: (coopAmount: number, isCoopRequest: boolean) => void
  onIgnoreCoop: (coopAmount: number, isCoopRequest: boolean) => void
}

export const SectionCheckoutCoop: React.FC<SectionCheckoutCoopProps> = ({
  items,
  masterDealerId,
  onUseCoop,
  onIgnoreCoop,
}) => {
  const { coop, hasCoop } = useChannel()
  const canRequestCoop = (coop.availableBalance > 0 && !hasCoop)
  const [coopSubtotal, setCoopSubtotal] = useState(0)
  const [loadingCoop, setLoadingCoop] = useState(true)
  const [coopAmountsForCart, setCoopAmountsForCart] = useState<
    Record<string, { amount: number; percentage: number }> | null | undefined
  >(undefined)
  const [loadingCoopAmountsForCart, setLoadingCoopAmountsForCart] =
    useState(false)
  const subtotal = CartHelper.getSubtotal(items)
  useEffect(() => {
    if (hasCoop && coop?.availableBalance && items) {
      setLoadingCoopAmountsForCart(true)
      CartHelper.getCoopAmountsForCart(masterDealerId, items)
        .then((coopAmountsForCart) => {
          setCoopAmountsForCart(coopAmountsForCart)
        })
        .catch(() => {
          setCoopAmountsForCart(undefined)
        })
        .finally(() => {
          setLoadingCoopAmountsForCart(false)
        })
    } else {
      setCoopAmountsForCart(undefined)
    }

    const fetchCoopAmountsForCart = async () => {
      setLoadingCoop(true)
      const coopTotal = await CartHelper.getCoopTotalForCart(
        masterDealerId,
        items
      )
      setCoopSubtotal(coopTotal)
      if (coopTotal > coop.availableBalance) {
        onIgnoreCoop(0, false)
      }
      setLoadingCoop(false)
    }

    fetchCoopAmountsForCart().catch((err) => console.error(err))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coop.availableBalance, items, masterDealerId])
  const difference = Math.round((subtotal - coopSubtotal) * 100) / 100

  const getCoopPercentageText = (id: string) => {
    return !!id && coopAmountsForCart && coopAmountsForCart[id]
      ? `${(coopAmountsForCart[id].percentage as number) * 100}%`
      : undefined
  }

  const getAmountAfterCoop = (price: number, id: string) => {
    return !!id && coopAmountsForCart && coopAmountsForCart[id]
      ? (price - (coopAmountsForCart[id].amount as number)).toFixed(2)
      : price
  }

  const getAmountAfterCoopText = (price: number, id: string) => {
    return `$${formatCurrency(
      Math.round((getAmountAfterCoop(price, id) as number) * 100).toString()
    )}`
  }

  return (
    <S.Container>
      <S.ProductList>
        {items.products.map((item: CartProduct, index: number) => {
          const {
            selectedVariantIndex,
            selectedDeliveryMethodIndex,
            selectedPrintAndShipIndex,
          } = item
          const variant = item.product.variants[selectedVariantIndex]
          return (
            <S.ProductItem key={`product-item-${index}`}>
              <S.ThumbnailContainer>
                <S.Thumbnail src={variant.media[0]} alt={item.product.title} />
              </S.ThumbnailContainer>
              <S.ProductDetail>
                <S.ProductRow>
                  <S.ProductTitle>{item.product.title}</S.ProductTitle>
                  <S.Text alignSelf="flex-start">
                    {`$${variant.printAndShipOptions[selectedPrintAndShipIndex]?.price}`}
                  </S.Text>
                </S.ProductRow>
                {((hasCoop || canRequestCoop) && coop?.availableBalance) && (
                  !loadingCoopAmountsForCart ? (
                    coopAmountsForCart &&
                    coopAmountsForCart[item.product._id as string] && (
                      <>
                        <S.ProductRow>
                          <S.ProductTitle>CO-OP %</S.ProductTitle>
                          <S.Text alignSelf="flex-start">
                            {getCoopPercentageText(item.product._id as string)}
                          </S.Text>
                        </S.ProductRow>
                        <S.ProductRow>
                          <S.ProductTitle>AMOUNT AFTER CO-OP</S.ProductTitle>
                          <S.Text alignSelf="flex-start">
                            {getAmountAfterCoopText(
                              CartHelper.getProductPrice(item),
                              item.product._id as string
                            )}
                          </S.Text>
                        </S.ProductRow>
                      </>
                    )
                  ) : (
                    <S.ProductRow>
                      <Spinner spinnerSize={4} />
                    </S.ProductRow>
                  )
                )}
                <S.Text color={'#9fa8b3'}>
                  {ProductHelper.getDeliveryMethod(
                    item.product.deliveryMethods[
                      selectedDeliveryMethodIndex - 1
                    ],
                    variant.printAndShipOptions[selectedPrintAndShipIndex]
                      ?.pieceCount
                  )}
                </S.Text>
                <S.Text color={'#9fa8b3'}>
                  {ProductHelper.getVariantTitle(variant)}
                </S.Text>
              </S.ProductDetail>
              <S.Line />
            </S.ProductItem>
          )
        })}
        {items.events.map((item: CartEvent, index: number) => {
          const { event } = item
          return (
            <S.ProductItem key={`event-item-${index}`}>
              <S.ThumbnailContainer>
                <S.Thumbnail
                  src={`${process.env.PUBLIC_URL}${EventHelper.getImageFile(
                    event.categories[0]
                  )}`}
                  alt={event.title}
                />
              </S.ThumbnailContainer>
              <S.ProductDetail>
                <S.ProductRow>
                  <S.ProductTitle>{item.event.title}</S.ProductTitle>
                  <S.Text alignSelf="flex-start">{`$${event.price}`}</S.Text>
                </S.ProductRow>
                {((hasCoop || canRequestCoop) && coop?.availableBalance) && (
                  !loadingCoopAmountsForCart ? (
                    coopAmountsForCart &&
                    coopAmountsForCart[item.event._id as string] && (
                      <>
                        <S.ProductRow>
                          <S.ProductTitle>CO-OP %</S.ProductTitle>
                          <S.Text alignSelf="flex-start">
                            {getCoopPercentageText(item.event._id as string)}
                          </S.Text>
                        </S.ProductRow>
                        <S.ProductRow>
                          <S.ProductTitle>AMOUNT AFTER CO-OP</S.ProductTitle>
                          <S.Text alignSelf="flex-start">
                            {getAmountAfterCoopText(
                              CartHelper.getEventPrice(item),
                              item.event._id as string
                            )}
                          </S.Text>
                        </S.ProductRow>
                      </>
                    )
                  ) : (
                    <S.ProductRow>
                      <Spinner spinnerSize={4} />
                    </S.ProductRow>
                  )
                )}
                <S.Text color={'#9fa8b3'}>
                  {EventHelper.getLocation(event)}
                </S.Text>
              </S.ProductDetail>
              <S.Line />
            </S.ProductItem>
          )
        })}
      </S.ProductList>
      <S.ProductRow>
        <S.ProductTitle>Subtotal</S.ProductTitle>
        <S.Text alignSelf="flex-start">${subtotal.toFixed(2)}</S.Text>
      </S.ProductRow>
      {(hasCoop && coop?.availableBalance) && (
        <S.ProductRow>
          <S.ProductTitle>Co-Op dollars to apply</S.ProductTitle>
          {loadingCoop ? (
            <Spinner spinnerSize={4} />
          ) : (
            <S.Text alignSelf="flex-start">${coopSubtotal.toFixed(2)}</S.Text>
          )}
        </S.ProductRow>
      )}
      <S.ProductRow>
        <S.ProductTitle>Total</S.ProductTitle>
        {canRequestCoop ? (
          <S.Text alignSelf="flex-start">${subtotal.toFixed(2)}</S.Text>
          ) : (
            <S.Text alignSelf="flex-start">${difference.toFixed(2)}</S.Text>
            )}
      </S.ProductRow>
      <S.ProductRow style={{ gap: '15px' }}>
        <S.Button
          onClick={() => onIgnoreCoop(0, false)}
          isSecondary={true}
          disabled={loadingCoop}
        >
          Continue with Credit Card Checkout
        </S.Button>
        {canRequestCoop && (
          <S.Button
          onClick={() => onUseCoop(coopSubtotal, true)}
          disabled={loadingCoop}
        >
          Request Co-Op Funds
        </S.Button>
        )}
        {hasCoop && coop.availableBalance > subtotal && (<S.Button
          onClick={() => onUseCoop(coopSubtotal, false)}
          disabled={loadingCoop}
        >
          Apply Co-Op and Proceed
        </S.Button>)
        }
      </S.ProductRow>
    </S.Container>
  )
}
