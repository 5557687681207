import * as S from './styles'

import React, { useEffect, useState } from 'react'
import { TabPanel, TabPanels, Tabs } from '@reach/tabs'

import MyAccount from '../../../pages/my-account'
import { RegistrationUserResponse } from '../Registration'
import TeamMembers from '../TeamMembers'
import { UserHelper } from '@monorepo/infra'

interface ProfileTemplateProps {
  profile: RegistrationUserResponse | null
}

interface TabsInterface {
  name: string
}
const ProfileTemplate: React.FC<ProfileTemplateProps> = ({ profile }) => {
  const [showCreateDialog, setShowCreateDialog] = useState(false)
  const [showEditDialog, setShowEditDialog] = useState(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)

  const profileTab = { name: 'Profile' }
  const teammembersTab = { name: 'Team Members' }
  const [tabs, setTabs] = useState<TabsInterface[]>([profileTab])

  const dialog = {
    showEditDialog,
    showCreateDialog,
    showDeleteDialog,
    setShowEditDialog,
    setShowCreateDialog,
    setShowDeleteDialog,
  }

  const [selectedIndex, setSelectedIndex] = React.useState(0)

  const handleChange = (index: number) => {
    setSelectedIndex(index)
  }

  useEffect(() => {
    if (profile?.groups?.length) {
      const isAdmin = UserHelper.isDealerAdmin(profile?.groups)
      setIsAdmin(isAdmin)
      if (isAdmin) {
        setTabs([profileTab, teammembersTab])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile])

  return (
    <S.Container>
      <S.Header>
        <S.Title>MY ACCOUNT</S.Title>
      </S.Header>

      <Tabs onChange={(index) => handleChange(index)}>
        <S.Wrapper>
          <S.TabList>
            {tabs.map((tab, index) => (
              <S.Tab selected={index === selectedIndex} index={index} key={index}>
                {tab?.name}
              </S.Tab>
            ))}
          </S.TabList>
          {selectedIndex === 1 && isAdmin && (
            <S.Button
              label="ADD TEAM MEMBER"
              onClick={() => setShowCreateDialog(true)}
            />
          )}
        </S.Wrapper>

        <TabPanels>
          <TabPanel>
            <MyAccount profile={profile} />
          </TabPanel>
          <TabPanel>
            <TeamMembers isAdmin={isAdmin} profile={profile} dialog={dialog} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </S.Container>
  )
}

export default ProfileTemplate
