import { Spinner } from '@monorepo/components'
import { CartHelper, useChannel, roundCurrency } from '@monorepo/infra'
import { CartItems } from '@monorepo/interfaces'
import React, { useEffect, useState } from 'react'
import * as S from '../styles'

interface SectionCheckoutTotalReviewProps {
  items: CartItems
  masterDealerId: string
  isUsingCoop: boolean
  isCoopRequest:boolean
  onReturn: () => void
  onContinue: () => void
  setCoopRequestTotal:(value:number)=> void
}

export const SectionCheckoutTotalReview: React.FC<SectionCheckoutTotalReviewProps> =
  ({ items, masterDealerId, isUsingCoop, isCoopRequest, onReturn, onContinue, setCoopRequestTotal }) => {
    const { coop, hasCoop } = useChannel()
    const canRequestCoop = (coop.availableBalance > 0 && !hasCoop)
    const [coopSubtotal, setCoopSubtotal] = useState(0)
    const [loadingCoop, setLoadingCoop] = useState(true)
    const subtotal = CartHelper.getSubtotal(items)
    useEffect(() => {
      const fetchCoopAmountsForCart = async () => {
        setLoadingCoop(true)
        const coopTotal = await CartHelper.getCoopTotalForCart(
          masterDealerId,
          items
        )
        setCoopSubtotal(coopTotal)
        setCoopRequestTotal(coopTotal)
        setLoadingCoop(false)
      }

      fetchCoopAmountsForCart().catch((err) => console.error(err))
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coop.availableBalance, items, masterDealerId])
    const difference = Math.round((subtotal - coopSubtotal) * 100) / 100
    const coopPercentage = roundCurrency((coopSubtotal / subtotal) * 100)
    return (
      <S.Container>
        <S.ProductRow>
          <S.ProductTitle>Total</S.ProductTitle>
          <S.Text alignSelf="flex-start">${subtotal.toFixed(2)}</S.Text>
        </S.ProductRow>
        {(isUsingCoop && (canRequestCoop || hasCoop) && coop?.availableBalance) ? (
          <>
            <S.ProductRow>
              <S.ProductTitle>Total Co-Op %</S.ProductTitle>
              {loadingCoop && isUsingCoop ? (
                <Spinner spinnerSize={4} />
              ) : (
                <S.Text alignSelf="flex-start">
                  {coopPercentage.toFixed(2)}%
                </S.Text>
              )}
            </S.ProductRow>
            <S.ProductRow>
              <S.ProductTitle>Total Co-Op Amount</S.ProductTitle>
              {loadingCoop && isUsingCoop ? (
                <Spinner spinnerSize={4} />
              ) : (
                <S.Text alignSelf="flex-start">
                  ${coopSubtotal.toFixed(2)}
                </S.Text>
              )}
            </S.ProductRow>
          </>
        ) : null}
        <S.ProductRow>
          <S.ProductTitle>Total to be Charged to Credit Card</S.ProductTitle>
          {(isUsingCoop && (canRequestCoop || hasCoop) && coop?.availableBalance) ? 
          (!loadingCoop ? (
              <S.Text alignSelf="flex-start">${difference.toFixed(2)}</S.Text>
            ) : (
              <Spinner spinnerSize={4} />
            )
          ) : (
            <S.Text alignSelf="flex-start">${subtotal.toFixed(2)}</S.Text>
          )}
        </S.ProductRow>
        <S.ProductRow style={{ gap: '15px' }}>
            <S.Button
              onClick={() => onReturn()}
              isSecondary={true}
              disabled={loadingCoop && isUsingCoop}
            >
              Back
            </S.Button>
          <S.Button
            onClick={() => onContinue()}
            disabled={loadingCoop && isUsingCoop}
          >
           {isCoopRequest ? 'Send Co-Op Request': 'Continue'}
          </S.Button>
        </S.ProductRow>
      </S.Container>
    )
  }
