/* eslint-disable @typescript-eslint/no-explicit-any */
import * as S from './styles'
import { TableBody } from '@material-ui/core'
import { ACBHistoryItem } from '@monorepo/interfaces'
import Dinero from 'dinero.js'
import moment from 'moment'

export interface SectionCoOpListProps {
  data?: ACBHistoryItem[]
  errorMessage?: string
}

const SectionCoOpList: React.FC<SectionCoOpListProps> = ({
  data,
  errorMessage,
}) => {
  const currentDate = moment(new Date())

  return (
    <S.Container>
      {Array.isArray(data) && data.length ? (
        <S.TableContainer>
          <S.Table aria-label="Co-Op Table">
            <S.TableHead>
              <S.TableHeadRow>
                <S.TableHeadCell style={{ width: '70%' }}>
                  Description
                </S.TableHeadCell>
                <S.TableHeadCell>Expiration Date</S.TableHeadCell>
                <S.TableHeadCell>Amount</S.TableHeadCell>
              </S.TableHeadRow>
            </S.TableHead>
            <TableBody>
              {data.map((item, index) => (
                <S.TbodyRow key={index}>
                  <S.TBodyCell scope="row" style={{ width: '70%' }}>
                    {item.TransactionDescription}
                  </S.TBodyCell>
                  <S.TBodyCell scope="row">
                    <S.ExpirationTag
                      data-expired={currentDate.isAfter(
                        moment.utc(item.FundExpiryDate)
                      )}
                    >
                      {`Expires ${moment
                        .utc(item.FundExpiryDate)
                        .format('MMM D, YYYY')}`}
                    </S.ExpirationTag>
                  </S.TBodyCell>
                  <S.TBodyCell scope="row">
                    {Dinero({
                      amount: Math.round(item.FundAmount * 100),
                    }).toFormat('$0,0.00')}
                  </S.TBodyCell>
                </S.TbodyRow>
              ))}
            </TableBody>
          </S.Table>
        </S.TableContainer>
      ) : (
        <S.EmptyMessage>{errorMessage || 'There is no data'}</S.EmptyMessage>
      )}
    </S.Container>
  )
}

export default SectionCoOpList
