import * as S from './styles'

import { CoopBar, Footer, Header } from '../components'
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'

import { PrivateRoute } from '@monorepo/infra'
import { createBrowserHistory } from 'history'
import { useKeycloak } from '@react-keycloak/web'
import { PageSpinner } from '@monorepo/components'

const Home = React.lazy(() => import('../pages'))
const Microsites = React.lazy(() => import('../pages/microsites'))
const Media = React.lazy(() => import('../pages/media'))
const mediaDetails = React.lazy(() => import('../pages/media/details'))
const AllProducts = React.lazy(() => import('../pages/all-products'))
const AdMaterials = React.lazy(() => import('../pages/ad-materials'))
const ProductDetails = React.lazy(() => import('../pages/all-products/details'))
const EventDetails = React.lazy(() => import('../pages/all-events/details'))
const AllEvents = React.lazy(() => import('../pages/all-events'))
const Cart = React.lazy(() => import('../pages/cart'))
const Checkout = React.lazy(() => import('../pages/checkout'))
const Profile = React.lazy(() => import('../pages/profile'))
const Resources = React.lazy(() => import('../pages/resources'))
const Support = React.lazy(() => import('../pages/support'))
const Terms = React.lazy(() => import('../pages/terms'))
const Impersonate = React.lazy(() => import('../pages/impersonate'))

const browserHistory = createBrowserHistory()

const Routes: React.FC = () => {
  const { keycloak, initialized } = useKeycloak()
  return (
    <S.Container>
      {initialized && keycloak.authenticated && (
        <div>
          <CoopBar />
          <Header />
        </div>
      )}
      <Suspense fallback={<PageSpinner />}>
        <Switch>
          <PrivateRoute exact path="/" component={Home} />
          <PrivateRoute exact path="/microsites" component={Microsites} />
          <PrivateRoute exact path="/media" component={Media} />
          <PrivateRoute
            exact
            path="/media/details/:sku"
            component={mediaDetails}
          />
          <PrivateRoute exact path="/all-products" component={AllProducts} />
          <PrivateRoute exact path="/ad-materials" component={AdMaterials} />
          <PrivateRoute
            exact
            path="/all-products/details/:id"
            component={ProductDetails}
          />
          <PrivateRoute
            exact
            path="/all-events/details/:id"
            component={EventDetails}
          />
          <PrivateRoute path="/all-events" component={AllEvents} />
          <PrivateRoute exact path="/cart" component={Cart} />
          <PrivateRoute exact path="/checkout" component={Checkout} />
          <PrivateRoute path="/profile" component={Profile} />
          <PrivateRoute exact path="/resources" component={Resources} />
          <PrivateRoute exact path="/contact" component={Support} />
          <PrivateRoute exact path="/terms" component={Terms} />
          <PrivateRoute
            exact
            path="/impersonate/:userEmailEncoded"
            component={Impersonate}
          />
          <Route render={() => <h1>Page 404</h1>} />
        </Switch>
      </Suspense>
      {initialized && keycloak.authenticated && <Footer />}
    </S.Container>
  )
}

export { Routes, browserHistory }
