"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChannelContext = void 0;
var react_1 = require("react");
exports.ChannelContext = (0, react_1.createContext)({
    associatedMasterDealerIds: [],
    selectedChannel: null,
    selectedMasterDealerId: null,
    hasCoop: false,
    groups: [],
    coop: {
        availableBalance: null,
        isCoopUpdating: null,
    },
    setState: function () { return undefined; },
    updateBalance: function () { return undefined; },
});
