"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hasImpersonatedUserPermissions = exports.hasPermissions = exports.getImpersonatedUserPermissions = exports.getFullUserName = exports.isMemberOf = exports.isImpersonatedUserDealerAdmin = exports.isDealerAdmin = exports.isImpersonatedUserAdmin = exports.isAdmin = void 0;
var keycloak_1 = require("./keycloak");
var utils_1 = require("../utils");
var isAdmin = function (groups) {
    var memberGroups = [
        'dealerAdmin',
        'eventVendorAdmin',
        'productVendorAdmin',
        'directvAdmin',
        'superAdmin',
        'vendorAdmin',
    ];
    return (0, exports.isMemberOf)(groups, memberGroups);
};
exports.isAdmin = isAdmin;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
var isImpersonatedUserAdmin = function (keycloackClient) {
    var _a;
    return (0, exports.isAdmin)((_a = (0, keycloak_1.getTokenExchangeParsed)(keycloackClient)) === null || _a === void 0 ? void 0 : _a.groups);
};
exports.isImpersonatedUserAdmin = isImpersonatedUserAdmin;
var isDealerAdmin = function (groups) {
    var memberGroups = ['dealerAdmin'];
    return (0, exports.isMemberOf)(groups, memberGroups);
};
exports.isDealerAdmin = isDealerAdmin;
var isImpersonatedUserDealerAdmin = function (
// eslint-disable-next-line @typescript-eslint/no-explicit-any
keycloackClient) {
    var _a;
    return (0, exports.isDealerAdmin)((_a = (0, keycloak_1.getTokenExchangeParsed)(keycloackClient)) === null || _a === void 0 ? void 0 : _a.groups);
};
exports.isImpersonatedUserDealerAdmin = isImpersonatedUserDealerAdmin;
var isMemberOf = function (userGroups, memberGroups) {
    return Array.isArray(userGroups) && Array.isArray(memberGroups)
        ? userGroups.some(function (userGroup) {
            return memberGroups.findIndex(function (memberGroup) {
                return new RegExp("^\\/?".concat(memberGroup, "$"), 'i').test(userGroup);
            }) !== -1;
        })
        : false;
};
exports.isMemberOf = isMemberOf;
var getFullUserName = function (firstName, lastName) {
    return "".concat(!!(firstName === null || firstName === void 0 ? void 0 : firstName.trim()) ? firstName === null || firstName === void 0 ? void 0 : firstName.trim() : '').concat(!!(firstName === null || firstName === void 0 ? void 0 : firstName.trim()) && !!(lastName === null || lastName === void 0 ? void 0 : lastName.trim()) ? ' ' : '').concat(!!(lastName === null || lastName === void 0 ? void 0 : lastName.trim()) ? lastName === null || lastName === void 0 ? void 0 : lastName.trim() : '');
};
exports.getFullUserName = getFullUserName;
var getImpersonatedUserPermissions = function (
// eslint-disable-next-line @typescript-eslint/no-explicit-any
keycloackClient) {
    var _a;
    return (_a = (0, keycloak_1.getTokenExchangeParsed)(keycloackClient)) === null || _a === void 0 ? void 0 : _a.permissions;
};
exports.getImpersonatedUserPermissions = getImpersonatedUserPermissions;
var hasPermissions = function (userPermissions, requiredPermissions, strict) {
    if (!Array.isArray(userPermissions) || !requiredPermissions) {
        return false;
    }
    if (!Array.isArray(requiredPermissions)) {
        requiredPermissions = [requiredPermissions];
    }
    return (requiredPermissions.length !== 0 &&
        (strict
            ? (0, utils_1.intersect)(userPermissions, requiredPermissions).length ===
                requiredPermissions.length
            : (0, utils_1.intersect)(userPermissions, requiredPermissions).length > 0));
};
exports.hasPermissions = hasPermissions;
var hasImpersonatedUserPermissions = function (
// eslint-disable-next-line @typescript-eslint/no-explicit-any
keycloackClient, requiredPermissions, strict) {
    return (0, exports.hasPermissions)((0, keycloak_1.getTokenExchangeParsed)(keycloackClient), requiredPermissions, strict);
};
exports.hasImpersonatedUserPermissions = hasImpersonatedUserPermissions;
