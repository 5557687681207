import { Order } from '@monorepo/interfaces'
import * as S from '../styles'

interface SectionCheckoutConfirmationProps {
  orderSent: Order | null
  isCoopRequest: boolean
}

export const SectionCheckoutConfirmation: React.FC<SectionCheckoutConfirmationProps> =
  ({ orderSent, isCoopRequest }) => {
    if (orderSent === null) return null
    return (
      <S.Container>
        {isCoopRequest ? (
          <>
          <S.ConfirmationTitle>Co-Op request sent</S.ConfirmationTitle>
          <S.ConfirmationOrderNumber>
              Order #{orderSent.number}
          </S.ConfirmationOrderNumber>
          <S.ConfirmationMessage>Your Co-Op request has been submitted and your order is saved.</S.ConfirmationMessage>
          </>
        ) : (
          <>
            <S.ConfirmationTitle>Payment Confirmed</S.ConfirmationTitle>
            <S.ConfirmationOrderNumber>
              Order #{orderSent.number}
            </S.ConfirmationOrderNumber>
            <S.ConfirmationThankYou>
              Thank You for your payment!
            </S.ConfirmationThankYou>
            <S.ConfirmationMessage>
              Your order has been completed!
            </S.ConfirmationMessage>
          </>
        )}
        <S.ConfirmationRow>

          {orderSent.customer.shippingAddress.contactName && orderSent.customer.shippingAddress.contactName !== '' &&
            (<S.ConfirmationRowItem>
              <S.ConfirmationMessage>CONTACT INFORMATION</S.ConfirmationMessage>
              <S.ConfirmationMessage>
                {`${orderSent.customer.shippingAddress.contactName} 
                \n${orderSent.customer.shippingAddress.email}
                \n${orderSent.customer.shippingAddress.phoneNumber}
                ${orderSent.customer.shippingAddress.asmEmail &&
                  orderSent.customer.shippingAddress.asmEmail !== ''
                  && `\n${orderSent.customer.shippingAddress.asmEmail}, ${orderSent.customer.shippingAddress.asmName}`}`
                  }
              </S.ConfirmationMessage>
            </S.ConfirmationRowItem>
            )}
          {orderSent.customer.shippingAddress.address1 &&
            orderSent.customer.shippingAddress.address1 !== '' &&
            (<S.ConfirmationRowItem>
              <S.ConfirmationMessage>SHIPPING ADDRESS</S.ConfirmationMessage>
              <S.ConfirmationMessage>
                {`${orderSent.customer.userInfo?.firstName} ${orderSent.customer.userInfo?.lastName}
                \n${orderSent.customer.shippingAddress.address1}
                \n${orderSent.customer.shippingAddress.city}, ${orderSent.customer.shippingAddress.state} ${orderSent.customer.shippingAddress.zipCode}`}
              </S.ConfirmationMessage>
            </S.ConfirmationRowItem>)}
        </S.ConfirmationRow>
      </S.Container>
    )
  }
