import * as S from './styles'

import { CartEvent, CartProduct, CartItems } from '@monorepo/interfaces'
import {
  CartHelper,
  EventHelper,
  ProductHelper,
  useCartContext,
  useChannel,
  ChannelHelper,
  formatCurrency,
} from '@monorepo/infra'
import React, { useCallback, useState, useEffect } from 'react'

import { Carousel } from '@monorepo/components'
import { useHistory } from 'react-router-dom'
import Dinero from 'dinero.js'
import { Spinner } from '@monorepo/components'

// TODO: Refactor into smaller components

const Cart: React.FC = () => {
  const history = useHistory()
  const { hasCoop, selectedMasterDealerId, selectedChannel, coop } =
    useChannel()
  const { items, removeProduct, removeEvent } = useCartContext()
  const [showRemoveModal, setShowRemoveModal] = useState(false)
  const [itemIndex, setItemIndex] = useState<number>(0)
  const [itemIsEvent, setItemIsEvent] = useState<boolean>(false)
  const [coopPercentages, setCoopPercentages] = useState<
    { [index: string]: unknown } | null | undefined
  >(undefined)
  const [subTotal, setSubTotal] = useState<number>(0)
  const [coopTotal, setCoopTotal] = useState<number>(0)
  const [loadingCoopPercentages, setLoadingCoopPercentages] = useState(false)
  const [loadingCoopTotalForCart, setLoadingCoopTotalForCart] = useState(false)
  const openModal = useCallback((index: number, isEvent: boolean) => {
    setItemIsEvent(isEvent)
    setItemIndex(index)
    setShowRemoveModal((show) => !show)
  }, [])
  const canRequestCoop = coop.availableBalance > 0 && !hasCoop
  const closeModal = useCallback(() => {
    setShowRemoveModal((show) => !show)
  }, [])

  useEffect(() => {
    setSubTotal(CartHelper.getSubtotal(items))

    if (!hasCoop || !coop?.availableBalance || !items) {
      setCoopPercentages(undefined)
      return
    }

    const { productMediaIds, eventMediaIds } = CartHelper.getMediaIds(items)
    const masterDealerId = ChannelHelper.getMasterDealerId(
      selectedMasterDealerId,
      selectedChannel
    )

    setLoadingCoopPercentages(true)
    CartHelper.getCoopPercentages(
      masterDealerId,
      productMediaIds,
      eventMediaIds
    )
      .then((coopPercentages) => {
        setCoopPercentages(
          CartHelper.getCoopPercentagesForCart(items, coopPercentages)
        )
      })
      .catch(() => {
        setCoopPercentages(undefined)
      })
      .finally(() => {
        setLoadingCoopPercentages(false)
      })

    setLoadingCoopTotalForCart(true)
    CartHelper.getCoopTotalForCart(masterDealerId, items)
      .then((result) => setCoopTotal(result))
      .catch(() => setCoopTotal(0))
      .finally(() => setLoadingCoopTotalForCart(false))
  }, [items, selectedMasterDealerId, selectedChannel])

  const getCoopTagText = (id: string) => {
    return !!id && coopPercentages && coopPercentages[id]
      ? `co-op eligible ${(coopPercentages[id] as number) * 100}%`
      : undefined
  }

  const getSubTotalText = () => {
    return !isNaN(subTotal)
      ? formatCurrency((subTotal * 100).toFixed(0))
      : '0.00'
  }

  const getEligibleCoopCalculatedPercentageText = () => {
    return !isNaN(coopTotal) && !isNaN(subTotal) && subTotal > 0
      ? `${((coopTotal / subTotal) * 100).toFixed(0)}%`
      : '0%'
  }

  const getCoopTotalText = () => {
    return !isNaN(coopTotal)
      ? formatCurrency((coopTotal * 100).toFixed(0))
      : '0.00'
  }

  const getTotalText = () => {
    return !isNaN(subTotal)
      ? !isNaN(coopTotal)
        ? formatCurrency(((subTotal - coopTotal) * 100).toFixed(0))
        : formatCurrency((subTotal * 100).toFixed(0))
      : '0.00'
  }

  return (
    <>
      <S.Container>
        <S.PageTitle>Shopping Cart</S.PageTitle>
        <S.Line />
        {items.events.length === 0 && items.products.length === 0 ? (
          <S.DetailContainer>No Products in Cart</S.DetailContainer>
        ) : (
          <S.DetailContainer>
            <S.ItemsListContainer>
              <S.ItemsList>
                {items.products.map((item: CartProduct, index: number) => {
                  const {
                    selectedVariantIndex,
                    selectedDeliveryMethodIndex,
                    selectedPrintAndShipIndex,
                    notes,
                    quantity,
                  } = item
                  const variant = item.product.variants[selectedVariantIndex]
                  const deliveryMethod =
                    item.product.deliveryMethods[
                      selectedDeliveryMethodIndex - 1
                    ]
                  return (
                    <S.ItemsListItemContainer key={`cart-product-${index}`}>
                      <S.ItemsListItem>
                        <Carousel
                          products={[
                            {
                              title: '',
                              images: variant.media,
                              link: '',
                              isNew: false,
                              subtitle: '',
                              aditionalInfo: '',
                              showInfo: false,
                            },
                          ]}
                          paddingBottom={'0'}
                        />
                        <S.ItemInfo>
                          <S.TitleContainer>
                            <S.TitleItem>
                              <S.ItemTitle>{item.product.title}</S.ItemTitle>
                            </S.TitleItem>
                            <S.TitleItem>
                              <S.ItemTitle>
                                {Dinero({
                                  amount: Math.round(
                                    CartHelper.getProductPrice(item) * 100
                                  ),
                                }).toFormat('$0,0.00')}
                              </S.ItemTitle>
                            </S.TitleItem>
                          </S.TitleContainer>
                          {canRequestCoop && coop?.availableBalance ? (
                            !loadingCoopPercentages ? (
                              coopPercentages &&
                              coopPercentages[item.product._id as string] && (
                                <S.CoopContainer>
                                  <S.CoopTag>
                                    {getCoopTagText(item.product._id as string)}
                                  </S.CoopTag>
                                </S.CoopContainer>
                              )
                            ) : (
                              <S.CoopContainer>
                                <Spinner spinnerSize={4} />
                              </S.CoopContainer>
                            )
                          ) : undefined}
                          <S.ItemDeliveryAndSizeContainer>
                            <S.ItemText>
                              {ProductHelper.getDeliveryMethod(
                                deliveryMethod,
                                variant.printAndShipOptions[
                                  selectedPrintAndShipIndex
                                ]?.pieceCount ?? 1
                              )}
                            </S.ItemText>
                            <S.ItemText
                              dangerouslySetInnerHTML={{
                                __html: ProductHelper.getVariantTitle(variant),
                              }}
                            />
                            {notes && notes !== '' && (
                              <S.ItemText>Notes: {notes}</S.ItemText>
                            )}
                            <S.ItemText>Quantity: {quantity}</S.ItemText>
                            {(item.dealerName ||
                              item.dealerAddress ||
                              item.dealerAddress2 ||
                              item.dealerPhone) && (
                              <>
                                {item.dealerName && (
                                  <S.ItemText>{`Dealer Name: ${item.dealerName}`}</S.ItemText>
                                )}
                                {item.dealerAddress && (
                                  <S.ItemText>{`Dealer Address: ${item.dealerAddress}`}</S.ItemText>
                                )}
                                {item.dealerAddress2 && (
                                  <S.ItemText>{`Dealer Address 2: ${item.dealerAddress2}`}</S.ItemText>
                                )}
                                {item.dealerPhone && (
                                  <S.ItemText>{`Dealer Phone: ${item.dealerPhone}`}</S.ItemText>
                                )}
                              </>
                            )}
                          </S.ItemDeliveryAndSizeContainer>
                          <S.RemoveButton
                            style={{ position: 'absolute', bottom: 0 }}
                            onClick={() => openModal(index, false)}
                          >
                            Remove
                          </S.RemoveButton>
                        </S.ItemInfo>
                      </S.ItemsListItem>
                      {/* <S.TextArea placeholder="Please share anything you would like us to know about your order." /> */}
                      <S.Line />
                    </S.ItemsListItemContainer>
                  )
                })}
              </S.ItemsList>
              <S.ItemsList>
                {items.events.map((item: CartEvent, index: number) => {
                  return (
                    <S.ItemsListItemContainer key={`cart-event-${index}`}>
                      <S.ItemsListItem>
                        <Carousel
                          products={[
                            {
                              title: '',
                              images: [EventHelper.getImageFile(EventHelper.getEventTypeDescription(item.event.type))],
                              link: '',
                              isNew: false,
                              subtitle: '',
                              aditionalInfo: '',
                              showInfo: false,
                            },
                          ]}
                          paddingBottom={'0'}
                        />
                        <S.ItemInfo>
                          <S.TitleContainer>
                            <S.TitleItem>
                              <S.ItemTitle>{item.event.title}</S.ItemTitle>
                            </S.TitleItem>
                            <S.TitleItem>
                              <S.ItemTitle>
                                {Dinero({
                                  amount: CartHelper.getEventPrice(item) * 100,
                                }).toFormat('$0,0.00')}
                              </S.ItemTitle>
                            </S.TitleItem>
                          </S.TitleContainer>
                          {canRequestCoop && coop?.availableBalance ? (
                            !loadingCoopPercentages ? (
                              coopPercentages &&
                              coopPercentages[item.event._id as string] && (
                                <S.CoopContainer>
                                  <S.CoopTag>
                                    {getCoopTagText(item.event._id as string)}
                                  </S.CoopTag>
                                </S.CoopContainer>
                              )
                            ) : (
                              <S.CoopContainer>
                                <Spinner spinnerSize={4} />
                              </S.CoopContainer>
                            )
                          ) : undefined}
                          <S.ItemDeliveryAndSizeContainer>
                            <S.ItemText>{item.event.categories[0]}</S.ItemText>
                            <S.ItemText>
                              {EventHelper.getLocation(item.event)}
                            </S.ItemText>
                          </S.ItemDeliveryAndSizeContainer>
                          <S.RemoveButton
                            style={{ position: 'absolute', bottom: 0 }}
                            onClick={() => openModal(index, true)}
                          >
                            Remove
                          </S.RemoveButton>
                        </S.ItemInfo>
                      </S.ItemsListItem>
                      <S.Line />
                    </S.ItemsListItemContainer>
                  )
                })}
              </S.ItemsList>
            </S.ItemsListContainer>
            <S.SummaryContainer>
              <S.SummaryTitle>Summary</S.SummaryTitle>
              <S.SummaryRow>
                <S.SummaryText flexBasis="80%">subtotal</S.SummaryText>
                <S.SummaryText flexBasis="20%">{`$${getSubTotalText()}`}</S.SummaryText>
              </S.SummaryRow>
              <S.SummaryRow>
                {canRequestCoop && coop?.availableBalance ? (
                  !loadingCoopTotalForCart ? (
                    <>
                      <S.SummaryText flexBasis="80%">
                        Eligible co-op %
                      </S.SummaryText>
                      <S.SummaryText flexBasis="20%">
                        {getEligibleCoopCalculatedPercentageText()}
                      </S.SummaryText>
                    </>
                  ) : (
                    <Spinner spinnerSize={4} />
                  )
                ) : undefined}
              </S.SummaryRow>
              <S.SummaryRow>
                {canRequestCoop && coop?.availableBalance ? (
                  !loadingCoopTotalForCart ? (
                    <>
                      <S.SummaryText flexBasis="80%">
                        Eligible co-op calculated at checkout
                      </S.SummaryText>
                      <S.SummaryText flexBasis="20%">{`$${getCoopTotalText()}`}</S.SummaryText>
                    </>
                  ) : (
                    <Spinner spinnerSize={4} />
                  )
                ) : undefined}
              </S.SummaryRow>
              <S.SummaryRow>
                <S.SummaryText flexBasis="80%">total</S.SummaryText>
                <S.SummaryText flexBasis="20%">
                  {`$${getTotalText()}`}
                </S.SummaryText>
              </S.SummaryRow>
              <S.Line />
              <S.Button onClick={() => history.push('/checkout')}>
                Checkout
              </S.Button>
            </S.SummaryContainer>
          </S.DetailContainer>
        )}
      </S.Container>
      <S.Dialog
        aria-label="Cart Dialog Remove Item"
        isOpen={showRemoveModal}
        onDismiss={closeModal}
      >
        <S.Container>
          <S.ItemTitle>Are you sure?</S.ItemTitle>
          <S.ButtonsContainer>
            <S.DialogButton
              label="Yes"
              colorOption="delicateGrey"
              onClick={() => {
                itemIsEvent ? removeEvent(itemIndex) : removeProduct(itemIndex)
                closeModal()
              }}
            >
              Checkout
            </S.DialogButton>
            <S.DialogButton
              label="No"
              colorOption="blue"
              onClick={() => closeModal()}
            >
              Checkout
            </S.DialogButton>
          </S.ButtonsContainer>
        </S.Container>
        <S.CloseModalButton
          colorOption="delicateGrey"
          icon="Close"
          borderRadius
          size="large"
          onClick={closeModal}
        />
      </S.Dialog>
    </>
  )
}

export default Cart
