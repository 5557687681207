import { OrderAddress, OrderItem } from '@monorepo/interfaces'
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import * as S from '../styles'
import { OrderHelper } from '@monorepo/infra'
import { Spinner } from '@monorepo/components'

interface CheckoutShippingEditProps {
  items: OrderItem[]
  masterDealerId: string
  isUsingCoop: boolean
  shipping: OrderAddress
  displayShippingAddressInp: boolean,
  displayContactInfosInp: boolean,
  onSubmit: (payment: OrderAddress) => void
}

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

const AddressSchema = (validateShippingAddress: boolean, validateContactInfo: boolean) => {
  const addressSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    address1: Yup.string().required('Address Line 1 is required'),
    address2: Yup.string().optional(),
    city: Yup.string().required('City is required'),
    state: Yup.string().max(2).required('State is required'),
    zipCode: Yup.string().max(10).required('Zip Code is required')
  })
  const contactSchema = Yup.object().shape({
    contactName: Yup.string().required('Contact name is required'),
    email: Yup.string().required('Email is required'),
    phoneNumber: Yup.string().required('Phone number is required'),
    asmName: Yup.string(),
    asmEmail: Yup.string()
  })

  return validateShippingAddress && validateContactInfo ? addressSchema.concat(contactSchema) : (
    validateShippingAddress ? addressSchema : contactSchema)
}

export const SectionCheckoutShippingEdit: React.FC<CheckoutShippingEditProps> = ({
  items,
  masterDealerId,
  isUsingCoop,
  shipping,
  displayContactInfosInp,
  displayShippingAddressInp,
  onSubmit,
}) => {
  const [coopSubtotal, setCoopSubtotal] = useState(0)
  const [loadingCoop, setLoadingCoop] = useState(true)
  const subtotal = OrderHelper.getItemSubtotal(items)
  useEffect(() => {
    const fetchCoopAmountsForCart = async () => {
      setLoadingCoop(true)
      const coopTotal = await OrderHelper.getCoopTotalForOrder(
        masterDealerId,
        items
      )
      setCoopSubtotal(coopTotal)
      setLoadingCoop(false)
    }

    fetchCoopAmountsForCart().catch((err) => console.error(err))
  }, [items, masterDealerId])
  const difference = isUsingCoop
    ? Math.round((subtotal - coopSubtotal) * 100) / 100
    : Math.round(subtotal * 100) / 100

  return (
    <S.Container>
      <Formik
        enableReinitialize={true}
        initialValues={shipping}
        validationSchema={AddressSchema(displayShippingAddressInp, displayContactInfosInp)}
        onSubmit={(values, actions) => {
          onSubmit(values)
          actions.setSubmitting(false)
          actions.resetForm()
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
        }) => (
          <Form>
            <S.Container>
              {displayShippingAddressInp && (
                <>
                  <S.PaymentInformationHeader>
                    <S.Title>Shipping Information</S.Title>
                    <S.Title>
                      {loadingCoop ? (
                        <Spinner />
                      ) : (
                        `Total: ${formatter.format(difference)}`
                      )}
                    </S.Title>
                  </S.PaymentInformationHeader>

                  <S.Input
                    id="firstName"
                    name="firstName"
                    type="text"
                    placeholder="Billing First Name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.firstName}
                  />
                  {touched.firstName &&
                    errors.firstName && (
                      <S.Text color="red">{errors.firstName}</S.Text>
                    )}
                  <S.Input
                    id="lastName"
                    name="lastName"
                    type="text"
                    placeholder="Billing Last Name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lastName}
                  />
                  {touched.lastName &&
                    errors.lastName && (
                      <S.Text color="red">{errors.lastName}</S.Text>
                    )}
                  <S.Input
                    id="address1"
                    name="address1"
                    type="text"
                    placeholder="Billing Address Line 1"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.address1}
                  />
                  {touched.address1 &&
                    errors.address1 && (
                      <S.Text color="red">{errors.address1}</S.Text>
                    )}
                  <S.Input
                    id="address2"
                    name="address2"
                    type="text"
                    placeholder="Billing Address Line 2"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.address2}
                  />
                  {touched.address2 &&
                    errors.address2 && (
                      <S.Text color="red">{errors.address2}</S.Text>
                    )}
                  <S.Input
                    id="city"
                    name="city"
                    type="text"
                    placeholder="Billing City"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.city}
                  />
                  {touched.city && errors.city && (
                    <S.Text color="red">{errors.city}</S.Text>
                  )}
                  <S.Select
                    name="state"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.state}
                    required
                  >
                    <option value="" disabled selected>
                      Billing State
                    </option>
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="DC">District Of Columbia</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                  </S.Select>
                  {touched.state &&
                    errors.state && (
                      <S.Text color="red">{errors.state}</S.Text>
                    )}
                  <S.Input
                    id="zipCode"
                    name="zipCode"
                    type="text"
                    placeholder="Billing Zip Code"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.zipCode}
                  />
                  {touched.zipCode &&
                    errors.zipCode && (
                      <S.Text color="red">{errors.zipCode}</S.Text>
                    )}
                </>)}
              {displayContactInfosInp && (
                <>
                  <S.PaymentInformationHeader>
                    <S.Title>Contact Information</S.Title>
                    {!displayShippingAddressInp && (
                    <S.Title>
                      {loadingCoop ? (
                        <Spinner />
                      ) : (
                        `Total: ${formatter.format(difference)}`
                      )}
                    </S.Title>)}
                  </S.PaymentInformationHeader>
                  <S.Input
                    id="contactName"
                    name="contactName"
                    type="text"
                    placeholder="Contact Name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.contactName}
                  />
                  {touched.contactName && errors.contactName && (
                    <S.Text color="red">{errors.contactName}</S.Text>
                  )}
                  <S.Input
                    id="email"
                    name="email"
                    type="text"
                    placeholder="Email Address"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  {touched.email && errors.email && (
                    <S.Text color="red">{errors.email}</S.Text>
                  )}
                  <S.Input
                    id="phoneNumber"
                    name="phoneNumber"
                    type="text"
                    placeholder="Phone Number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phoneNumber}
                  />
                  {touched.phoneNumber && errors.phoneNumber && (
                    <S.Text color="red">{errors.phoneNumber}</S.Text>
                  )}
                  <S.Input
                    id="asmName"
                    name="asmName"
                    type="text"
                    placeholder="ASM Name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.asmName}
                  />
                  {touched.asmName && errors.asmName && (
                    <S.Text color="red">{errors.asmName}</S.Text>
                  )}
                  <S.Input
                    id="asmEmail"
                    name="asmEmail"
                    type="text"
                    placeholder="ASM Email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.asmEmail}
                  />
                  {touched.asmEmail && errors.asmEmail && (
                    <S.Text color="red">{errors.asmEmail}</S.Text>
                  )}
                </>
              )}
              <S.Button type="submit">Move to Payment</S.Button>
            </S.Container>
          </Form>
        )}
      </Formik>
    </S.Container>
  )
}
