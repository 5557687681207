import { CartProduct, CartItems, CartEvent } from '@monorepo/interfaces'
import {
  EventHelper,
  ProductHelper,
  CartHelper,
  useChannel,
  ChannelHelper,
} from '@monorepo/infra'
import React, { useEffect, useState } from 'react'
import { Spinner } from '@monorepo/components'
import * as S from '../styles'

interface SectionCheckoutProductsProps {
  items: CartItems | null
  isUsingCoop: boolean
  isFreeOrder: boolean
  coopAmount: number
}

export const SectionCheckoutProducts: React.FC<SectionCheckoutProductsProps> =
  ({ items, isUsingCoop, coopAmount, isFreeOrder }) => {
    let subtotal = 0

    const { hasCoop, selectedMasterDealerId, selectedChannel, coop } =
      useChannel()
      const canRequestCoop = (coop.availableBalance > 0 && !hasCoop)
    const [coopPercentages, setCoopPercentages] = useState<
      { [index: string]: unknown } | null | undefined
    >(undefined)
    const [loadingCoopPercentages, setLoadingCoopPercentages] = useState(false)

    useEffect(() => {
      if (!isUsingCoop || !hasCoop || !coop?.availableBalance || !items) {
        setCoopPercentages(undefined)
        return
      }

      const masterDealerId = ChannelHelper.getMasterDealerId(
        selectedMasterDealerId,
        selectedChannel
      )
      const { productMediaIds, eventMediaIds } = CartHelper.getMediaIds(items)

      setLoadingCoopPercentages(true)
      CartHelper.getCoopPercentages(
        masterDealerId,
        productMediaIds,
        eventMediaIds
      )
        .then((coopPercentages) => {
          setCoopPercentages(
            CartHelper.getCoopPercentagesForCart(items, coopPercentages)
          )
        })
        .catch(() => {
          setCoopPercentages(undefined)
        })
        .finally(() => {
          setLoadingCoopPercentages(false)
        })
    }, [items, selectedMasterDealerId, selectedChannel])

    if (items === null) return null

    const getCoopPercentageText = (id: string) => {
      return !!id && coopPercentages && coopPercentages[id]
        ? `${(coopPercentages[id] as number) * 100}%`
        : undefined
    }

    return (
      <S.Container>
        <S.ProductList>
          {items.products.map((item: CartProduct, index: number) => {
            const {
              selectedVariantIndex,
              selectedDeliveryMethodIndex,
              selectedPrintAndShipIndex,
            } = item
            const variant = item.product.variants[selectedVariantIndex]
            subtotal += isFreeOrder ? parseFloat("0"):parseFloat(
              variant.printAndShipOptions[selectedPrintAndShipIndex].price
            )
            return (
              <S.ProductItem key={`product-item-${index}`}>
                <S.ThumbnailContainer>
                  <S.Thumbnail
                    src={variant.media[0]}
                    alt={item.product.title}
                  />
                </S.ThumbnailContainer>
                <S.ProductDetail>
                  <S.ProductRow>
                    <S.ProductTitle>{item.product.title}</S.ProductTitle>
                    <S.Text alignSelf="flex-start">
                      $
                      {isFreeOrder ? "0" :
                        variant.printAndShipOptions[selectedPrintAndShipIndex]
                          .price
                      }
                    </S.Text>
                  </S.ProductRow>
                  {(isUsingCoop && hasCoop && coop?.availableBalance) || canRequestCoop? (
                    !loadingCoopPercentages ? (
                      coopPercentages &&
                      coopPercentages[item.product._id as string] && (
                        <S.ProductRow>
                          <S.ProductTitle>CO-OP %</S.ProductTitle>
                          <S.Text alignSelf="flex-start">
                            {getCoopPercentageText(item.product._id as string)}
                          </S.Text>
                        </S.ProductRow>
                      )
                    ) : (
                      <S.ProductRow>
                        <Spinner spinnerSize={4} />
                      </S.ProductRow>
                    )
                  ) : undefined}
                  <S.Text color={'#9fa8b3'}>
                    {ProductHelper.getDeliveryMethod(
                      item.product.deliveryMethods[
                        selectedDeliveryMethodIndex - 1
                      ],
                      variant.printAndShipOptions[selectedPrintAndShipIndex]
                        .pieceCount
                    )}
                  </S.Text>
                  <S.Text color={'#9fa8b3'}>
                    {ProductHelper.getVariantTitle(variant)}
                  </S.Text>
                </S.ProductDetail>
              </S.ProductItem>
            )
          })}
          {items.events.map((item: CartEvent, index: number) => {
            subtotal += parseFloat(item.event.price)
            return (
              <S.ProductItem key={`event-item-${index}`}>
                <S.ThumbnailContainer>
                  <S.Thumbnail
                    src={`${process.env.PUBLIC_URL}${EventHelper.getImageFile(
                      item.event.categories[0]
                    )}`}
                    alt={item.event.title}
                  />
                </S.ThumbnailContainer>
                <S.ProductDetail>
                  <S.ProductRow>
                    <S.ProductTitle>{item.event.title}</S.ProductTitle>
                    <S.Text alignSelf="flex-start">${item.event.price}</S.Text>
                  </S.ProductRow>
                  {(isUsingCoop && hasCoop && coop?.availableBalance) || canRequestCoop ? (
                    !loadingCoopPercentages ? (
                      coopPercentages &&
                      coopPercentages[item.event._id as string] && (
                        <S.ProductRow>
                          <S.ProductTitle>CO-OP %</S.ProductTitle>
                          <S.Text alignSelf="flex-start">
                            {getCoopPercentageText(item.event._id as string)}
                          </S.Text>
                        </S.ProductRow>
                      )
                    ) : (
                      <S.ProductRow>
                        <Spinner spinnerSize={4} />
                      </S.ProductRow>
                    )
                  ) : undefined}
                  <S.Text color={'#9fa8b3'}>
                    {EventHelper.getLocation(item.event)}
                  </S.Text>
                </S.ProductDetail>
              </S.ProductItem>
            )
          })}
        </S.ProductList>
        <S.Line />
        <S.ProductRow>
          <S.ProductTitle>Subtotal</S.ProductTitle>
          <S.Text alignSelf="flex-start">${subtotal.toFixed(2)}</S.Text>
        </S.ProductRow>
        {(isUsingCoop && hasCoop && coop?.availableBalance)? (
          <S.ProductRow>
            <S.ProductTitle>Co-Op dollars to apply</S.ProductTitle>
            <S.Text alignSelf="flex-start">${coopAmount.toFixed(2)}</S.Text>
          </S.ProductRow>
        ) : undefined}
        <S.Line />
        <S.ProductRow>
          <S.TotalTitle>Total to be charged</S.TotalTitle>
          <S.TotalNumber>${(subtotal - coopAmount).toFixed(2)}</S.TotalNumber>
        </S.ProductRow>
      </S.Container>
    )
  }
