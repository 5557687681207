/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback, useEffect, useState, useRef } from 'react'
import {
  useChannel,
  useMerchandisingURL,
  ChannelHelper,
  UserHelper,
  KeycloakHelper,
} from '@monorepo/infra'

import { Merchandise } from '@monorepo/interfaces'
import { useKeycloak } from '@react-keycloak/web'

const securityProfileBaseUrl = new URL(
  'https://tmzwebapi.azurewebsites.net/Security/Profile'
)

export interface SecurityProfile {
  ErrorOccured: boolean
  UPN: string
  FirstName: string
  LastName: string
  PhoneNumber: string
  Address: string
  City: string
  State: string
  ZipCode: string
  MasterDealerName: string
  MasterDealerId: string
  CorpId: string
  DealerInformations: DealerInformation[]
}

export interface DealerInformation {
  ErrorOccured: boolean
  MasterDealerId: string
  UPN: string
  DealerCode: string
  DealerChannel: string
  PreferredDealer: boolean
  LocationName: string
  PhoneNumber: string
  Address: string
  City: string
  State: string
  ZipCode: string
}

interface UseMerchandiseURL {
  merchandiseURL: string
  error: string | null | undefined
}

export const useMerchandiseURL = (): UseMerchandiseURL => {
  const channel = useChannel()
  const { selectedChannel, selectedMasterDealerId, groups } = channel
  const { keycloak } = useKeycloak()
  const user = KeycloakHelper.getTokenParsed(keycloak)

  const [merchandiseURL, setMerchandiseURL] = useState('')

  const prevSelectedChannel = useRef<string | null | undefined>()
  const prevSelectedMasterDealerId = useRef<string | null | undefined>()
  const isChannelContextChanged = (
    selectedChannel: string | null | undefined,
    selectedMasterDealerId: string | null | undefined
  ): boolean =>
    prevSelectedChannel.current !== selectedChannel ||
    prevSelectedMasterDealerId.current !== selectedMasterDealerId
  const [error, setError] = useState<string | null | undefined>(undefined)

  const getMerchandiseUrl = useCallback(
    async (principalEmail: string, securityProfile: SecurityProfile) => {
      if (!channel) return

      if (
        !selectedChannel ||
        !selectedMasterDealerId ||
        (merchandiseURL &&
          !isChannelContextChanged(selectedChannel, selectedMasterDealerId))
      )
        return

      const selectedDealerInformations =
        securityProfile?.DealerInformations?.find(
          (di) =>
            di.DealerChannel === selectedChannel &&
            di.MasterDealerId ===
              selectedMasterDealerId + selectedChannel.charAt(0)
        )

      // Use 9999999999L for all PV (2252), NMG (3550), and DSI (2251) dealers who are not dealerAdmins
      const dto: Merchandise = {
        channel: channel.selectedChannel,
        subdealer:
          !UserHelper.isImpersonatedUserDealerAdmin(keycloak) &&
          !UserHelper.isDealerAdmin(groups)
            ? 'T'
            : 'F',
        retail: !ChannelHelper.canSeeCoop(
          channel.selectedMasterDealerId,
          channel.selectedChannel,
          UserHelper.isImpersonatedUserDealerAdmin(keycloak) ||
            UserHelper.isDealerAdmin(groups)
        )
          ? 'T'
          : 'F',
        dealerCode: `${selectedMasterDealerId}${channel?.selectedChannel?.charAt(0)}`,
        locationName: selectedDealerInformations?.LocationName ?? '',
        locationPhone: selectedDealerInformations?.PhoneNumber ?? '',
        masterDealerName: securityProfile?.MasterDealerName ?? '',
        principalEmail,
      }

      const merchandiseUrl = useMerchandisingURL(dto)

      if (merchandiseUrl.error) {
        return
      }

      setMerchandiseURL(merchandiseUrl.urlEncoded || '')
    },
    [channel, merchandiseURL, groups]
  )

  const getSecurityProfile = useCallback(
    (email = '') =>
      fetch(`${securityProfileBaseUrl}/${email}/Get`)
        .then((response) => response.json())
        .then((data) => data as SecurityProfile),
    []
  )

  useEffect(() => {
    if (!channel) return

    const { selectedChannel, selectedMasterDealerId } = channel

    if (
      merchandiseURL &&
      !isChannelContextChanged(selectedChannel, selectedMasterDealerId)
    )
      return

    try {
      const _getMerchandiseUrl = async () => {
        const securityProfile = await getSecurityProfile(user?.email)

        await getMerchandiseUrl(user?.email as string, securityProfile)

        prevSelectedChannel.current = selectedChannel
        prevSelectedMasterDealerId.current = selectedMasterDealerId
      }

      _getMerchandiseUrl()
    } catch (err) {
      setError(`An error ocurred retrieving the Merchandise URL: ${err}`)
    }
  }, [getMerchandiseUrl, getSecurityProfile, keycloak, merchandiseURL, channel])

  return { merchandiseURL, error }
}
