import { Spinner } from '@monorepo/components'
import { OrderHelper, roundCurrency } from '@monorepo/infra'
import { OrderItem } from '@monorepo/interfaces'
import React from 'react'
import * as S from '../styles'

interface SectionCheckoutCoopProps {
  items: OrderItem[]
  loadingCoop: boolean
  coopSubtotal: number
  onUseCoop:  (coopAmount: number, isCoopRequest: boolean) => void
  onIgnoreCoop: (coopAmounts: number) => void
  status?: number,
  approvedCoopRequest: boolean
  showCoopSummary: boolean
  canRequestCoop: boolean
}

export const SectionCheckoutCoop: React.FC<SectionCheckoutCoopProps> = ({
  items,
  loadingCoop,
  coopSubtotal,
  onUseCoop,
  onIgnoreCoop,
  status,
  approvedCoopRequest = false,
  showCoopSummary = true,
  canRequestCoop=false
}) => {
  const subtotal = OrderHelper.getItemSubtotal(items)
  // payment total with coop
  const difference = Math.round((subtotal - coopSubtotal) * 100) / 100
  const coopPercentage = roundCurrency((coopSubtotal / subtotal) * 100)
  return (
    <S.Container>
      <S.ProductList>
        {items.map((item: OrderItem, index: number) => {
          const { product } = item
          return (
            <S.ProductItem key={`product-item-${index}`}>
              <S.ThumbnailContainer>
                <S.Thumbnail src={product.thumbnailUrl} alt={product.title} />
              </S.ThumbnailContainer>
              <S.ProductDetail>
                <S.ProductRow>
                  <S.ProductTitle>{product.title}</S.ProductTitle>
                  <S.Text alignSelf="flex-start">{`$ ${
                    parseFloat(item.price) * item.quantity
                  }`}</S.Text>
                </S.ProductRow>
              </S.ProductDetail>
              <S.Line />
            </S.ProductItem>
          )
        })}
      </S.ProductList>
      <S.ProductRow>
        <S.ProductTitle>Subtotal</S.ProductTitle>
        <S.Text alignSelf="flex-start">${subtotal.toFixed(2)}</S.Text>
      </S.ProductRow>
      {showCoopSummary && (
        <>
          <S.ProductRow>
            <S.ProductTitle>Co-Op %</S.ProductTitle>
            {loadingCoop ? (
              <Spinner spinnerSize={4} />
            ) : (
              <S.Text alignSelf="flex-start">{coopPercentage.toFixed(2)}%</S.Text>
            )}
          </S.ProductRow>
          <S.ProductRow>
            <S.ProductTitle>{approvedCoopRequest ? 'Co-Op' : 'Co-Op dollars to apply'}</S.ProductTitle>
            {loadingCoop ? (
              <Spinner spinnerSize={4} />
            ) : (
              <S.Text alignSelf="flex-start">${coopSubtotal.toFixed(2)}</S.Text>
            )}
          </S.ProductRow>
        </>
      )}
      <S.ProductRow>
        <S.ProductTitle>{approvedCoopRequest ? 'Dealer spend' : 'Total'}</S.ProductTitle>
        <S.Text alignSelf="flex-start">${difference.toFixed(2)}</S.Text>
      </S.ProductRow>
      <S.ProductRow style={{ gap: '15px' }}>
        <S.Button
          onClick={() => onIgnoreCoop(0)}
          isSecondary={true}
          disabled={loadingCoop}
        >
          Continue with Credit Card Checkout
        </S.Button>
        {status !== 6 && canRequestCoop && (
          <S.Button
          onClick={() => onUseCoop(coopSubtotal, true)}
          disabled={loadingCoop}
        >
          Request Co-Op Funds
        </S.Button>
        )}
        {(status !== 6 && showCoopSummary) ?
          (<S.Button
            onClick={() => onUseCoop(coopSubtotal, false)}
            disabled={loadingCoop}
          >
            Apply Co-Op and Proceed
          </S.Button>) : ''
        }
      </S.ProductRow>
    </S.Container>
  )
}
