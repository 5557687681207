import * as S from './styles'

import { useCartContext, EventHelper } from '@monorepo/infra'

import { Button } from '@monorepo/components'
import { Event } from '@monorepo/interfaces'
import { SectionCartList, SectionPopular } from '../..'
import { useState } from 'react'

type DetailsProps = {
  event?: Event
}

function getDate(date: Date | undefined): string {
  if (!date) return ''
  const newDate = new Date(date)
  const month = newDate.getMonth() + 1
  const day = newDate.getDate()
  const year = newDate.getFullYear().toString().substr(-2)
  return month + '/' + day + '/' + year
}

function getEventType(event: number) {
  const eventTypeOption = EventHelper.getEventTypeOptions().find(
    (item) => parseInt(item.value) === event
  )
  return eventTypeOption ? eventTypeOption.label : 'Unknown'
}

const Details: React.FC<DetailsProps> = ({ event }) => {
  const [showDrawer, setShowDrawer] = useState(false)

  const { addEvent } = useCartContext()

  if (!event || Array.isArray(event)) return <div>Loading...</div>

  const cartEvent = {
    event,
    quantity: 1,
  }

  return (
    <main>
      <S.SectionEvent>
        <S.SectionEventImage>
          <img src={EventHelper.getImageFile(EventHelper.getEventTypeDescription(event.type))} alt={event.title} />
        </S.SectionEventImage>
        <S.SectionEventItem>
          <S.EventTitle>{event.title}</S.EventTitle>
          <S.EventPrice>{`$${event.price}`}</S.EventPrice>
          <S.EventPriceDescription>
            Price determined after booking
          </S.EventPriceDescription>
          {event.startDate && event.endDate && (
            <S.EventParagraph>
              {`${getDate(event.startDate)} - ${getDate(event.endDate)}`}
              <br />
              {`${event.city}, ${event.state}, ${event.zipCode}`}
            </S.EventParagraph>
          )}
          <S.EventParagraph>
            <strong>Event Type:</strong> {getEventType(event.type)}
          </S.EventParagraph>
          <S.EventParagraph>{event.description}</S.EventParagraph>
          <S.ButtonWrapper>
            <Button
              colorOption="blue"
              label="ADD TO CART"
              onClick={() => {
                addEvent(cartEvent)
                setShowDrawer(true)
              }}
            />
          </S.ButtonWrapper>
        </S.SectionEventItem>
      </S.SectionEvent>

      <SectionPopular detailsPage featuredTitle="What's New" />
      <S.CartDrawer
        anchor="right"
        open={showDrawer}
        onClose={() => setShowDrawer(false)}
      >
        <SectionCartList onClose={() => setShowDrawer(false)} />
      </S.CartDrawer>
    </main>
  )
}

export default Details
